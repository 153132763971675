.card {
    position: relative;
    height: 143px;
    width: 300px;
    
}
.title {
    gap: 8px;
}

.shippingDate {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
}